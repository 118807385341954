import classNames from 'classnames';
import {Button, BUTTON_THEMES} from 'components/Button';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {getResizedImageUrlByCode} from 'core/helpers/imageResize';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {BankCardList} from 'modules/footer/components/BankCardList';
import {TopSection} from 'modules/footer/components/TopSection';
import {FOOTER_TEXT} from 'modules/footer/constants';
import {OPEN_CHAT_TITLE} from 'modules/Jivo/constants';
import {jivoOpen} from 'modules/Jivo/helpers';
import {MenuLink} from 'modules/menu/components/MenuLink';
import {selectFooterMenuFormatted, selectSocialMedia} from 'modules/menu/selectors';
import {MODALS} from 'modules/modals/constants';
import {showModal} from 'modules/modals/thunks';
import {useRouter} from 'next/router';
// import appStoreIcon from 'public/icons/app-store-mini.svg';
import chevronDownIcon from 'public/icons/chevron-down.svg';
// import googlePlayIcon from 'public/icons/google-play-mini.svg';
// import ruStoreIcon from 'public/icons/ru-store-mini.svg';
import React, {memo, useCallback, useMemo, useState} from 'react';

// import {MobileAppsSection} from '../MobileAppsSection';
import style from './style.module.scss';

// const appLinksList = [
//     {
//         icon: <SvgIcon svg={appStoreIcon} />,
//         path: '#',
//     },
//     {
//         icon: <SvgIcon svg={googlePlayIcon} />,
//         path: '#',
//     },
//     {
//         icon: <SvgIcon svg={ruStoreIcon} />,
//         path: '#',
//     },
// ];

const columnStyle = getGridColumnStyles();
const columnNavItemStyle = getGridColumnStyles({md: 3});

export const Footer = memo(function Footer() {
    // TODO: Delete after adding functionality or pages

    const [openSubMenuIdx, setOpenSubMenuIdx] = useState<string | null>(null);
    const menuListFormatted = useAppSelector(selectFooterMenuFormatted);
    const social = useAppSelector(selectSocialMedia);
    const dispatch = useAppDispatch();

    const {query} = useRouter();

    const isNeedAdditionalMargin = useMemo<boolean>(() => Boolean(query.id), [query.id]);

    const onOpenSubMenu = useCallback<(number: string) => void>(
        (idx) => (idx === openSubMenuIdx ? setOpenSubMenuIdx(null) : setOpenSubMenuIdx(idx)),
        [openSubMenuIdx]
    );

    const handleOpenFeedbackModal = useCallback(() => dispatch(showModal(MODALS.FEEDBACK.name)), [dispatch]);

    return (
        <footer className={classNames(style.footer, {[style.withAdditionalMargin]: isNeedAdditionalMargin})}>
            {/* <MobileAppsSection />*/}
            <TopSection />
            <Grid as="section" className={style.mainGrid} container noRowGap>
                <Grid as="nav" className={classNames(style.nav, columnStyle)} noRowGap>
                    {menuListFormatted.map(({data, list}) => {
                        const {id, title} = data;

                        return (
                            <div className={classNames(style.navItem, columnNavItemStyle)} key={id}>
                                <button className={style.mainItem} onClick={() => onOpenSubMenu(id)} type="button">
                                    <Typography color="gray100" element="h5" variant="h6">
                                        {title}
                                    </Typography>

                                    <div
                                        className={classNames(style.mainContentItemIcon, {
                                            [style.isActive]: id === openSubMenuIdx,
                                        })}
                                    >
                                        <SvgIcon svg={chevronDownIcon} />
                                    </div>
                                </button>

                                {
                                    <ul
                                        className={classNames(style.mainContentSubList, {
                                            [style.isActive]: id === openSubMenuIdx,
                                        })}
                                    >
                                        {list.map(({id: subItemId, title: subItemTitle, url}) => {
                                            return (
                                                <li className={style.mainContentSubItem} key={subItemId}>
                                                    <Typography variant="p-regular">
                                                        <MenuLink url={url}>{subItemTitle}</MenuLink>
                                                    </Typography>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                }
                            </div>
                        );
                    })}
                    <div className={classNames(style.navItem, style.hotLineBlock, columnNavItemStyle)}>
                        <div className={classNames(style.navItem, style.hotLineInner)}>
                            <Typography className={style.hotLineTitle} element="h5" variant="h6">
                                {FOOTER_TEXT.hotLine.title}
                            </Typography>
                            <button className={style.openChatButton} onClick={jivoOpen} type="button">
                                <Typography color="gray80" variant="p">
                                    {OPEN_CHAT_TITLE}
                                </Typography>
                            </button>
                            <Typography color="secondary100" variant="p-small">
                                Ежедневно с 09:00 до 18:00
                            </Typography>
                        </div>
                        <div className={style.social}>
                            <Typography element="h5" variant="h6">
                                {FOOTER_TEXT.contacts.title}
                            </Typography>
                            <Typography color="gray80" variant="p">
                                <a href="mailto:info@karacahome.ru">info@karacahome.ru</a>
                            </Typography>
                            <ul className={style.socialList}>
                                {social?.sections.map(({icon, url}) => (
                                    <li className={style.socialItem} key={url?.value}>
                                        <a href={url?.value} rel="noreferrer" target="_blank">
                                            {icon?.url && (
                                                <img alt={icon?.title} src={getResizedImageUrlByCode(icon?.url)} />
                                            )}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className={classNames(style.navItem, columnNavItemStyle)}>
                        <div className={style.feedback}>
                            <Typography className={style.feedbackTitle} color="gray80" element="p" variant="p">
                                Форма для всех ваших вопросов и предложений
                            </Typography>
                            <Button
                                className={style.feedbackBtn}
                                isNotAdaptive
                                onClick={handleOpenFeedbackModal}
                                theme={BUTTON_THEMES.secondary}
                            >
                                Обратная связь
                            </Button>
                        </div>
                        {/* <div className={style.social}>*/}
                        {/*    <Typography color="gray80" variant="p">*/}
                        {/*        Загрузить мобильное приложение*/}
                        {/*    </Typography>*/}
                        {/*    <ul className={style.socialList}>*/}
                        {/*        {appLinksList.map(({icon, path}) => (*/}
                        {/*            <li className={style.socialItem} key={path}>*/}
                        {/*                <a href={path} rel="noreferrer" target="_blank">*/}
                        {/*                    {icon}*/}
                        {/*                </a>*/}
                        {/*            </li>*/}
                        {/*        ))}*/}
                        {/*    </ul>*/}
                        {/* </div>*/}
                    </div>
                </Grid>
            </Grid>
            <section className={classNames(style.copyRightSection, columnStyle)}>
                <div className={style.copyRightContainer}>
                    <Typography className={style.copyRightTitle} variant="p">
                        © ООО «Карача Рус», 2023—2024
                    </Typography>
                    <div className={style.payment}>
                        <Typography color="gray80" variant="p">
                            {FOOTER_TEXT.payments.title}
                        </Typography>
                        <BankCardList className={style.bankCardList} />
                    </div>
                </div>
            </section>
        </footer>
    );
});
