import {ProductCardService} from 'modules/product-card/services/ProductCardService';
import {PRODUCTS_SELECTIONS} from 'modules/products/constants';
import {ProductShort} from 'new-models';
import {PRODUCT_ROUTE} from 'routing/constants';

export const setIsSelection = (path?: string | string[]) => PRODUCTS_SELECTIONS.some((selection) => selection === path);

export const getProductUrl = (product?: Pick<ProductShort, 'id' | 'code'>) => {
    if (!product) {
        return '';
    }

    const {id, code} = product;

    return `${PRODUCT_ROUTE}/${ProductCardService.makeProductCardUrl(code, id)}`;
};
