import {JivoMobileButtonClass} from 'modules/Jivo/constants';
import {useEffect} from 'react';

const {HighOffset} = JivoMobileButtonClass;

export const useDisableJivoMobileButtonHighOffset = () => {
    useEffect(() => {
        const isHighOffsetPage = document.body.classList.contains(HighOffset);

        if (isHighOffsetPage) {
            document.body.classList.remove(HighOffset);
        }

        return () => {
            if (isHighOffsetPage) {
                document.body.classList.add(HighOffset);
            }
        };
    }, []);
};
