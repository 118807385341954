import {RUNTIME_CSS_VARS} from 'core/constants';
import {useElementHeightWithCssVar} from 'core/hooks/useElementHeightWithCssVar';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {DIYCatalogMenuList} from 'modules/categories/components/Menu/DIY/DIYCatalogMenuList';
import {useDisableJivoMobileButtonHighOffset} from 'modules/Jivo/hooks/useDisableJivoMobileButtonHighOffset';
import {selectCatalogPromotionsMenu, selectHeaderCatalogMenuFormatted} from 'modules/menu/selectors';
import {Search} from 'modules/search-popup/components/Search';
import {useSearch} from 'modules/search-popup/hooks/useSearch';
import React, {FC, useRef} from 'react';

import style from './style.module.scss';

export const DIYCatalogMenuMasonry: FC = () => {
    const {handleCloseSearchModal, handleSearchFieldFocus} = useSearch();
    const searchContainerRef = useRef<HTMLDivElement>(null);

    const catalogMenuList = useAppSelector(selectHeaderCatalogMenuFormatted);
    const catalogPromoList = useAppSelector(selectCatalogPromotionsMenu);

    useDisableJivoMobileButtonHighOffset();

    useElementHeightWithCssVar(searchContainerRef, RUNTIME_CSS_VARS.headerSearchMobileHeight);

    return (
        <>
            <div className={style.catalogMenu}>
                <div className={style.catalogMenuSearchFieldContainer} ref={searchContainerRef}>
                    <Search
                        className={style.catalogMenuSearchField}
                        isHeader
                        onClose={handleCloseSearchModal}
                        onFocus={handleSearchFieldFocus}
                        placeholder="Найти..."
                    />
                </div>
                <nav className={style.catalogMenuContent}>
                    <DIYCatalogMenuList catalogPromoList={catalogPromoList} categoriesList={catalogMenuList} />
                </nav>
            </div>
        </>
    );
};
