import classNames from 'classnames';
import {Button, BUTTON_COLORS} from 'components/Button';
import {COLLAPSE_VARIANTS, CollapseAnimatedOnScrollMix} from 'components/CollapseAnimatedMix';
import {CollapseAnimatedOnTop} from 'components/CollapseAnimatedOnTop';
import {InfoForBuyerDropdown} from 'components/InfoForBuyerDropdown';
import {SvgIcon} from 'components/SvgIcon';
import {RUNTIME_CSS_VARS} from 'core/constants';
import {useElementHeightWithCssVar} from 'core/hooks/useElementHeightWithCssVar';
import {useIsNeedRoute} from 'core/hooks/useIsNeedRoute';
// import {useIsScrollBottom} from 'core/hooks/useIsScrollBottom';
import {useScroll} from 'core/hooks/useScroll';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {HeaderElement} from 'modules/header/components/HeaderElement';
import {HeaderMenuElements} from 'modules/header/components/HeaderMenuElements';
import {OpenChatTextButton} from 'modules/header/components/OpenChatTextButton';
import {HeaderLocationItem} from 'modules/locations/components/HeaderLocationItem';
import {getMenuItemUrl} from 'modules/menu/helpers';
import {selectHeaderTopRightMenu} from 'modules/menu/selectors';
import {MODALS} from 'modules/modals/constants';
import {hideModal, showModal} from 'modules/modals/thunks';
import {selectIsPortalVisible} from 'modules/portal/selectors';
import {Search} from 'modules/search-popup/components/Search';
import {selectIsSearchPanelVisible} from 'modules/search-popup/selectors';
import {THEMES} from 'modules/theme/constants';
import {useTheme} from 'modules/theme/hooks/useTheme';
import Link from 'next/link';
import catalogIcon from 'public/icons/catalog-second.svg';
import closeIcon from 'public/icons/close.svg';
import logoIcon from 'public/icons/logo.svg';
import React, {memo, SyntheticEvent, useCallback, useEffect, useMemo, useRef} from 'react';
import {BRAND_LIST_ROUTE, CART_ROUTE, PRODUCT_PATH} from 'routing/constants';

import style from './style.module.scss';

export const Header = memo(function Header() {
    // const isNeedRoute = useIsNeedRoute([HOME_ROUTE]);

    const isNeedRoute = false;
    const isNeedShadow = !useIsNeedRoute([PRODUCT_PATH, BRAND_LIST_ROUTE, CART_ROUTE]);
    const isInContext = useIsNeedRoute([CART_ROUTE]);
    const dispatch = useAppDispatch();

    const isNotAtTop = useScroll(15, 0);
    // const isScrollBottom = useIsScrollBottom();

    // const isBottomCollapseOnTop = useIsNeedRoute([PRODUCT_PATH]);

    // const variant = isBottomCollapseOnTop ? COLLAPSE_VARIANTS.onTop : COLLAPSE_VARIANTS.onScrollBottom;
    const variant = COLLAPSE_VARIANTS.onTop;

    // const iDisplayed = isBottomCollapseOnTop ? isNotAtTop : isScrollBottom;
    const iDisplayed = isNotAtTop;

    const {theme} = useTheme();

    const isMenuOpen = useAppSelector(selectIsPortalVisible(MODALS.CATALOG_MENU.name));
    const isSearchPanelVisible = useAppSelector(selectIsSearchPanelVisible);
    const menuTopRight = useAppSelector(selectHeaderTopRightMenu);
    const headerRef = useRef<HTMLDivElement>(null);

    const handleCatalogClick = useCallback(() => {
        if (isMenuOpen) {
            dispatch(hideModal(MODALS.CATALOG_MENU.name));
            return;
        }

        dispatch(showModal(MODALS.CATALOG_MENU.name));
    }, [dispatch, isMenuOpen]);

    const handleHeaderClick = useCallback(
        (e: SyntheticEvent) => {
            e.stopPropagation();
            if (isMenuOpen) {
                dispatch(hideModal(MODALS.CATALOG_MENU.name));
            }
        },
        [dispatch, isMenuOpen]
    );

    useEffect(() => {
        if (isSearchPanelVisible) {
            dispatch(hideModal(MODALS.CATALOG_MENU.name));
        }
    }, [dispatch, isSearchPanelVisible]);

    useElementHeightWithCssVar(headerRef, RUNTIME_CSS_VARS.headerHeight);

    const isDefault = useMemo<boolean>(
        () => isNotAtTop || isMenuOpen || isSearchPanelVisible || !isNeedRoute,
        [isMenuOpen, isNeedRoute, isNotAtTop, isSearchPanelVisible]
    );

    return (
        <header
            className={classNames(
                style.headerFixed,
                {
                    [style.isDefault]: isDefault,
                    [style.isNotAtTop]: isNotAtTop,
                    [style.isNeedShadow]: isNeedShadow,
                    [style[theme]]: theme,
                    [style.isInContext]: isInContext,
                },
                'header-fixed-component'
            )}
            onClick={handleHeaderClick}
            ref={headerRef}
            role="presentation"
        >
            <CollapseAnimatedOnTop
                className={style.headerTopContainer}
                isCollapsed={!isInContext}
                noOverflowHiddenIfShow
            >
                <div className={style.headerTop}>
                    <ul className={style.headerTopSide}>
                        <li>
                            <HeaderLocationItem />
                        </li>
                    </ul>
                    <ul className={style.headerTopSide}>
                        <li>
                            <InfoForBuyerDropdown />
                        </li>
                        <li className={style.headerLinkItem}>
                            <OpenChatTextButton />
                        </li>
                    </ul>
                </div>
            </CollapseAnimatedOnTop>
            <div className={style.container}>
                <div className={style.leftBlock}>
                    <Link href="/">
                        <a className={style.logo}>
                            <SvgIcon svg={logoIcon} />
                        </a>
                    </Link>
                    <div
                        className={classNames(style.catalogButtonContainer, {
                            [style.isDisplayed]: iDisplayed && !isInContext,
                        })}
                    >
                        <Button
                            className={style.catalogButton}
                            color={
                                theme === THEMES.dark && !isDefault
                                    ? BUTTON_COLORS.secondaryColor
                                    : BUTTON_COLORS.primaryColor
                            }
                            leftIcon={<SvgIcon svg={isMenuOpen ? closeIcon : catalogIcon} />}
                            onClick={handleCatalogClick}
                            variant="p"
                        >
                            Каталог
                        </Button>
                    </div>

                    <Search
                        className={classNames(style.search, {
                            [style.active]: isSearchPanelVisible,
                        })}
                        placeholder="Искать товары"
                    />
                </div>
                <div className={style.rightBlock}>
                    <HeaderMenuElements />
                </div>
            </div>
            <CollapseAnimatedOnScrollMix
                className={style.headerBottomContainer}
                isCollapsed={!isInContext}
                variant={variant}
            >
                <div className={style.headerBottom}>
                    <Button
                        className={style.catalogButton}
                        color={
                            theme === THEMES.dark && !isDefault
                                ? BUTTON_COLORS.secondaryColor
                                : BUTTON_COLORS.primaryColor
                        }
                        leftIcon={<SvgIcon svg={isMenuOpen ? closeIcon : catalogIcon} />}
                        onClick={handleCatalogClick}
                        variant="p"
                    >
                        Каталог
                    </Button>
                    <ul className={style.headerLinkList}>
                        {menuTopRight?.sections.map(({id, title, url, titleColor}) => (
                            <li key={id}>
                                <Link href={getMenuItemUrl(url)} key={id} passHref>
                                    <a>
                                        <HeaderElement titleColor={titleColor}>{title}</HeaderElement>
                                    </a>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </CollapseAnimatedOnScrollMix>
        </header>
    );
});
