import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {mindBoxService} from 'modules/analytics/services/MindBoxService';
import {TMindboxCartProduct} from 'modules/analytics/types';
import {makePatchBasket} from 'modules/cart/helpers';
import {updateCart, updateCartBatch} from 'modules/cart/thunks';
import {getPrice, getProductImageUrl} from 'modules/maxma/helpers/price';
import {maxmaService} from 'modules/maxma/service';
import {getProductUrl} from 'modules/products/helpers';
import {selectUserData} from 'modules/user/selectors';
import {useCallback} from 'react';

export const useAddToCart = () => {
    const profile = useAppSelector(selectUserData);
    const dispatch = useAppDispatch();

    return useCallback(
        (cartItems: TMindboxCartProduct[], type?: 'add' | 'remove') => {
            if (profile) {
                mindBoxService.addToCart(cartItems, profile);
            }

            const cartProducts = cartItems.map(makePatchBasket);

            if (1 === cartProducts.length) {
                const productData = cartItems[0] || {};

                const {id, media, price, quantity, title} = productData;

                if ('add' === type) {
                    maxmaService.addToCart({
                        price: getPrice(price),
                        product: {
                            id,
                            imageUrl: getProductImageUrl(media),
                            title,
                            url: window.location.origin + getProductUrl(productData),
                        },
                    });
                }

                if (!quantity) {
                    maxmaService.removeFromCart({
                        product: {
                            id,
                        },
                    });
                }

                dispatch(updateCart(cartProducts[0]));
                return;
            }

            dispatch(updateCartBatch(cartProducts));
        },
        [dispatch, profile]
    );
};
