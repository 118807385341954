import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {useAddToFavourite} from 'modules/analytics/hooks/useAddToFavourite';
import {selectFavouritesIdList} from 'modules/favourites/selector';
import {removeProductInFavouritesList} from 'modules/favourites/thunks';
import {getPrice, getProductImageUrl} from 'modules/maxma/helpers/price';
import {maxmaService} from 'modules/maxma/service';
import {useProductUrl} from 'modules/products/hooks/useProductUrl';
import {ProductShort} from 'new-models';
import {useCallback, useEffect, useState} from 'react';

interface IUseFavouritesReturns {
    addToFavourites: () => void;
    removeProductInFavourites: () => void;
    isChecked: boolean;
    switchFavourites: (checked: boolean) => void;
    setIsChecked: (checked: boolean) => void;
}

export const useFavourites = (product: ProductShort): IUseFavouritesReturns => {
    const dispatch = useAppDispatch();
    const favouritesIdList = useAppSelector(selectFavouritesIdList);
    const [isChecked, setIsChecked] = useState(false);
    const productUrl = useProductUrl({...product});

    const addToFavourite = useAddToFavourite();

    const addToFavourites = useCallback(() => {
        const {id, media, price, title} = product;

        maxmaService.addToFavorite({
            price: getPrice(price),
            product: {
                id,
                imageUrl: getProductImageUrl(media),
                title,
                url: window.location.origin + productUrl,
            },
        });

        addToFavourite(product);
    }, [addToFavourite, product, productUrl]);

    const removeProductInFavourites = useCallback(() => {
        maxmaService.removeFromFavorite({
            product: {
                id: product.id,
            },
        });

        dispatch(removeProductInFavouritesList({productId: product.id}));
    }, [dispatch, product]);

    const switchFavourites = useCallback(
        (checked: boolean) => {
            setIsChecked(checked);

            if (checked) {
                addToFavourites();
                return;
            }

            removeProductInFavourites();
        },
        [addToFavourites, removeProductInFavourites]
    );

    useEffect(() => {
        setIsChecked(favouritesIdList.includes(product.id));
    }, [favouritesIdList, product]);

    return {
        addToFavourites,
        isChecked,
        removeProductInFavourites,
        setIsChecked,
        switchFavourites,
    };
};
