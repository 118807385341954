import {Toast} from 'components/Toast';
import {DEBOUNCE_DELAY} from 'core/constants';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {checkIsNumber} from 'core/regex';
import {TClosureCallback} from 'core/types';
import debounce from 'lodash/debounce';
import {useAddToCart} from 'modules/analytics/hooks/useAddToCart';
import {TOAST_NOTIFICATIONS} from 'modules/cart/constants';
import {selectCartProductCount} from 'modules/cart/selectors';
import {CartService} from 'modules/cart/services/CartService';
import {updateCart} from 'modules/cart/thunks';
import {getPrice, getProductImageUrl} from 'modules/maxma/helpers/price';
import {maxmaService} from 'modules/maxma/service';
import {useProductUrl} from 'modules/products/hooks/useProductUrl';
import {ProductBase} from 'new-models';
import React, {ChangeEventHandler, FocusEventHandler, useCallback, useEffect, useMemo, useState} from 'react';
import {toast} from 'react-toastify';

export interface IUseCartProduct {
    handleCartCountAddClick: () => void;
    handleCartCountRemoveClick: () => void;
    handleCartCountChange: ChangeEventHandler<HTMLInputElement>;
    handleCartCounterInputBlur: FocusEventHandler<HTMLInputElement>;
    cartProductCount: number | null;
    isProductInCart: boolean;
    isLocalProductInCart: boolean;
}

export const useCartProduct: TClosureCallback<ProductBase, IUseCartProduct> = (product) => {
    const dispatch = useAppDispatch();
    const id = product.id;
    const productUrl = useProductUrl({...product});

    const addToCart = useAddToCart();

    const productCount = useAppSelector(selectCartProductCount(id));

    // для реакции, не дожидаясь ответа от бэка
    const [localCartProductCount, setLocalCartProductCount] = useState<number | null>(productCount);

    useEffect(() => {
        setLocalCartProductCount(productCount);
    }, [productCount]);

    const debounceFn = debounce(
        (cartProduct: ProductBase, quantity: number, type?: 'add' | 'remove') =>
            addToCart([{...cartProduct, quantity}], type),
        DEBOUNCE_DELAY
    );

    const debounceBlur = debounce((elem: HTMLInputElement) => elem.blur(), DEBOUNCE_DELAY);

    const cartProductCount = useMemo<number | null>(() => localCartProductCount, [localCartProductCount]);

    const handleCartCountAddClick = useCallback(() => {
        setLocalCartProductCount((initialCount) => {
            const increasedCount = (initialCount ?? 0) + 1;
            setTimeout(() => CartService.abortUpdateCartRequest(id), 0);
            debounceFn(product, increasedCount, 'add');

            if (!initialCount) {
                toast(
                    <Toast hasIcon={false} isNeedCloseButton text={TOAST_NOTIFICATIONS.PRODUCT_ADDED} theme="info" />,
                    {
                        className: 'notification cart-notification',
                    }
                );
            }

            return increasedCount;
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCartCountRemoveClick = useCallback(() => {
        setLocalCartProductCount((initialCount) => {
            const decreasedCount = (initialCount ?? 0) - 1;
            setTimeout(() => CartService.abortUpdateCartRequest(id), 0);
            debounceFn(product, decreasedCount);

            return decreasedCount;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCartCountChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
        const value = e.target.value;

        if (!checkIsNumber(value) && '' !== value) {
            return;
        }

        if ('' === value) {
            setLocalCartProductCount(null);
            return;
        }

        setLocalCartProductCount(Number(value));

        debounceBlur(e.target);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCartCounterInputBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
        (e) => {
            const value = e.target.value;

            if ('' !== value) {
                const {media, price, title} = product;

                if ('0' === value) {
                    maxmaService.removeFromCart({
                        product: {
                            id,
                        },
                    });
                } else {
                    maxmaService.addToCart({
                        price: getPrice(price),
                        product: {
                            id,
                            imageUrl: getProductImageUrl(media),
                            title,
                            url: window.location.origin + productUrl,
                        },
                    });
                }

                dispatch(
                    updateCart({
                        productId: id,
                        quantity: Number(localCartProductCount),
                    })
                );
                return;
            }

            setLocalCartProductCount(0);
            dispatch(
                updateCart({
                    productId: id,
                    quantity: 0,
                })
            );

            maxmaService.removeFromCart({
                product: {
                    id,
                },
            });
        },
        [dispatch, id, localCartProductCount, product, productUrl]
    );

    return {
        cartProductCount,
        handleCartCountAddClick,
        handleCartCountChange,
        handleCartCountRemoveClick,
        handleCartCounterInputBlur,
        isLocalProductInCart: Boolean(cartProductCount),
        isProductInCart: Boolean(productCount),
    };
};
