import {Typography} from 'components/Typography';
import {OPEN_CHAT_TITLE} from 'modules/Jivo/constants';
import {jivoOpen} from 'modules/Jivo/helpers';

import style from './style.module.scss';

export const OpenChatTextButton: FC = () => {
    return (
        <button className={style.root} onClick={jivoOpen} type="button">
            <Typography variant="p-regular">{OPEN_CHAT_TITLE}</Typography>
        </button>
    );
};
