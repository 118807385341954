import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {MODAL_STATES, SCENARIOS, STRATEGIES, VIEWS} from 'modules/authentication/constants';
import {AuthContext} from 'modules/authentication/context';
import {useCheckAndConfirm} from 'modules/authentication/hooks/additional/useCheckAndConfirm';
import {useModalParamsHandler} from 'modules/authentication/hooks/handlers/useModalParamsHandler';
import {useSuccessExecutionHandler} from 'modules/authentication/hooks/handlers/useSuccessExecutionHandler';
import {IAuthBySmsStrategyCbs} from 'modules/authentication/types';
import {maxmaService} from 'modules/maxma/service';
import {MODALS} from 'modules/modals/constants';
import {showModal} from 'modules/modals/thunks';
import {useCallback, useContext} from 'react';

export const useAuthBySmsStrategy = (): IAuthBySmsStrategyCbs => {
    const dispatch = useAppDispatch();

    const {fieldsParams} = useContext(AuthContext);
    const phone = fieldsParams?.phone ?? '';

    const modalParamsHandler = useModalParamsHandler();
    const successExecutionHandler = useSuccessExecutionHandler();

    const {confirmPhoneHandler} = useCheckAndConfirm(SCENARIOS.authentication);

    const startAuthBySms = useCallback(() => {
        modalParamsHandler({
            isBackButton: false,
            modalState: MODAL_STATES.auth,
            modalView: VIEWS.auth,
            strategy: STRATEGIES.authBySms,
        });

        dispatch(showModal(MODALS.MODAL_DIALOG.name));
    }, [dispatch, modalParamsHandler]);

    const doAuthBySms = useCallback<IAuthBySmsStrategyCbs['doAuthBySms']>(
        async (values, formikHelpers) => {
            if (!phone) {
                return;
            }
            const response = await confirmPhoneHandler(values, formikHelpers);

            if (204 === response.status) {
                maxmaService.authSuccess({
                    phoneNumber: phone,
                });

                await successExecutionHandler(response);
            }
        },
        [confirmPhoneHandler, phone, successExecutionHandler]
    );

    return {doAuthBySms, startAuthBySms};
};
