import classNames from 'classnames';
import {Anchor} from 'components/Anchor';
import {Button, BUTTON_THEMES} from 'components/Button';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {Input} from 'components/Input';
import {Toast} from 'components/Toast';
import {Typography} from 'components/Typography';
import {isMobileByScreen} from 'core/helpers';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {TPropsWithClassName} from 'core/types';
import {Field, Form, Formik} from 'formik';
import {FieldProps} from 'formik/dist/Field';
import {FormikConfig} from 'formik/dist/types';
import {ERRORS} from 'modules/authentication/constants';
import {emailSubscribe} from 'modules/common/thunks';
import {maxmaService} from 'modules/maxma/service';
import {SubscribeDataRequest} from 'new-models';
import Link from 'next/link';
import React, {useCallback} from 'react';
import {toast} from 'react-toastify';
import * as yup from 'yup';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {}

const initialValues: SubscribeDataRequest = {
    agreementConfirmed: true,
    email: '',
};

const validationSchema = yup.object({
    email: yup.string().trim().email(ERRORS.incorrectEmail).required(ERRORS.emailRequired),
});

const leftColumnStyle = getGridColumnStyles({md: 5});
const rightColumnStyle = getGridColumnStyles({md: 7});

export const TopSection: FC<IProps> = ({className}) => {
    const dispatch = useAppDispatch();

    const showSuccessToast = useCallback(() => {
        toast(
            <Toast
                hasIcon={false}
                isNeedCloseButton
                text="Вы подписаны на рассылку! На вашу почту уже отправлено письмо."
                theme="info"
            />,
            {
                className: 'notification',
                position: isMobileByScreen() ? 'bottom-center' : 'bottom-right',
            }
        );
    }, []);

    const handleSubscribe = useCallback<FormikConfig<SubscribeDataRequest>['onSubmit']>(
        async (values) => {
            showSuccessToast();
            dispatch(emailSubscribe(values));

            maxmaService.subscribeToEmailPromo({
                email: values.email,
                subscribed: true,
            });
        },
        [dispatch, showSuccessToast]
    );

    return (
        <section className={classNames(style.root, className)}>
            <Grid className={style.content} container>
                <div className={classNames(style.titleBlock, leftColumnStyle)}>
                    <Typography className={style.title} color="gray100" element="h5" variant="h5">
                        Будьте в курсе новостей
                    </Typography>
                    <Typography className={style.description} color="gray80" element="p" variant="p">
                        Подпишитесь на email-рассылку и первыми узнавайте о наших акциях, скидках и главных новинках
                    </Typography>
                </div>
                <div className={classNames(style.formBlock, rightColumnStyle)}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubscribe}
                        validationSchema={validationSchema}
                    >
                        {({handleSubmit}) => (
                            <Form className={style.subscribeForm} onSubmit={handleSubmit}>
                                <Field name="email">
                                    {({field, meta}: FieldProps) => (
                                        <Input {...field} errorText={meta.error} labelText="E-mail" />
                                    )}
                                </Field>
                                <Button
                                    className={style.submitBtn}
                                    isNotAdaptive
                                    theme={BUTTON_THEMES.primary}
                                    type="submit"
                                >
                                    Подписаться
                                </Button>
                            </Form>
                        )}
                    </Formik>
                    <div className={style.agreementBlock}>
                        <Typography className={style.agreementText} variant="p-small">
                            Нажимая кнопку “Подписаться”, я даю согласие на получение рекламной рассылки и обработку
                        </Typography>{' '}
                        <Link href="/info/privacy-security" passHref>
                            <Anchor size="small">персональных данных</Anchor>
                        </Link>
                    </div>
                </div>
            </Grid>
        </section>
    );
};
