import {isBrowserContext} from 'core/helpers';
import {
    IAddToCartData,
    IAddToFavoriteData,
    IAuthSuccessData,
    IRemoveFromCartData,
    IRemoveFromFavoriteData,
    ISubmitOrderData,
    ISubscribeToEmailPromoData,
    IViewCatalogPageData,
    IViewProductPageData,
} from 'modules/maxma/types';

class MaxmaService {
    constructor() {
        this.init();
    }

    private init = () => {
        if (!isBrowserContext()) {
            return;
        }

        (function (e) {
            e.ClTrack =
                e.ClTrack ||
                function () {
                    // eslint-disable-next-line prefer-rest-params
                    e.ClTrack.queue.push([arguments, Number(new Date())]);
                };

            e.ClTrack.queue = e.ClTrack.queue || [];
        })(window);
    };

    private sendEvent = (name: string, data: any) => {
        if (!isBrowserContext()) {
            return;
        }

        if (window.showMaxma) {
            console.info(name, data);
        }

        window?.ClTrack(name, data);
    };

    public addToCart = (data: IAddToCartData) => {
        this.sendEvent('addToCart', data);
    };

    public addToFavorite = (data: IAddToFavoriteData) => {
        this.sendEvent('addToFav', data);
    };

    public authSuccess = (data: IAuthSuccessData) => {
        this.sendEvent('addClientInfo', data);
    };

    public clearCart = () => {
        this.sendEvent('setCart', {items: []});
    };

    public removeFromCart = (data: IRemoveFromCartData) => {
        this.sendEvent('removeFromCart', data);
    };

    public removeFromFavorite = (data: IRemoveFromFavoriteData) => {
        this.sendEvent('removeFromFav', data);
    };

    public submitOrder = (data: ISubmitOrderData) => {
        this.sendEvent('submitOrder', data);
    };

    public subscribeToEmailPromo = (data: ISubscribeToEmailPromoData) => {
        this.sendEvent('addClientInfo', data);
    };

    public viewCatalogPage = (data: IViewCatalogPageData) => {
        this.sendEvent('categoryView', data);
    };

    public viewProductPage = (data: IViewProductPageData) => {
        this.sendEvent('productView', data);
    };
}

export const maxmaService = new MaxmaService();
