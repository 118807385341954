import {PRICE_DENOMINATOR} from 'core/constants';
import {getRuntimeConfig} from 'core/next/helpers';
import {MediaGroupVisual, ProductPrice} from 'new-models';

export const formatPrice = (price?: number): string => {
    if (!price) {
        return '';
    }

    return String(price / PRICE_DENOMINATOR);
};

export const getPrice = (priceData?: ProductPrice): string => {
    if (!priceData) {
        return '';
    }

    const {main, mainDiscount} = priceData;
    const priceMain = main?.price;
    const priceMainDiscount = mainDiscount?.price;
    const price = priceMainDiscount && priceMainDiscount < priceMain ? priceMainDiscount : priceMain;

    return formatPrice(price);
};

export const getProductImageUrl = (media?: MediaGroupVisual[]): string => {
    if (!media || !media.length) {
        return '';
    }

    const item = media[0];

    let url = '';

    if ('url' in item) {
        url = item.url;
    }

    if ('urlL' in item) {
        url = item.urlL;
    }

    return getRuntimeConfig().NEXT_PUBLIC_BACKEND_HOST + url;
};
