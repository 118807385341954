import {Formik} from 'formik';
import AddressAddModal from 'modules/addresses/components/AddressAddModal';
import AddressEditModal from 'modules/addresses/components/AddressEditModal';
import {CheckoutMain} from 'modules/checkout/components/CheckoutMain';
import {CheckoutTitle} from 'modules/checkout/components/CheckoutTitle';
import {CheckoutTotals} from 'modules/checkout/components/CheckoutTotals';
import {IProps as ITabBarCheckoutProps, TabBarCheckout} from 'modules/checkout/components/TabBarCheckout';
import {CheckoutContext} from 'modules/checkout/context';
import {useCheckoutForm} from 'modules/checkout/hooks/useCheckoutForm';
import {CheckoutPageLayout} from 'modules/checkout/layouts/CheckoutPageLayout';
import {useSetJivoMobileButtonHighOffset} from 'modules/Jivo/hooks/useSetJivoMobileButtonHighOffset';
import {Modal} from 'modules/modals/components/Modal';
import {MODALS} from 'modules/modals/constants';
import {useIsSkeleton} from 'modules/skeleton/hooks/useIsSkeleton';
import React, {memo, useCallback, useContext} from 'react';

export const CheckoutPage = memo(function CheckoutPage() {
    const isSkeleton = useIsSkeleton();
    const {isCartLoading} = useContext(CheckoutContext);

    useSetJivoMobileButtonHighOffset();

    const renderTabBarCheckout: FC<ITabBarCheckoutProps> = useCallback(
        ({inView}) => {
            return !isCartLoading || !isSkeleton ? <TabBarCheckout inView={inView} /> : null;
        },
        [isCartLoading, isSkeleton]
    );

    const form = useCheckoutForm();

    return (
        <>
            <Formik<typeof form.initialValues>
                enableReinitialize
                initialValues={form.initialValues}
                innerRef={(ref) => (form.formRef.current = ref)}
                onSubmit={form.handleSubmit}
                validationSchema={form.validationSchema}
            >
                {() => (
                    <CheckoutPageLayout
                        FooterComponent={renderTabBarCheckout}
                        HeaderComponent={CheckoutTitle}
                        LeftComponent={CheckoutMain}
                        RightComponent={CheckoutTotals}
                    />
                )}
            </Formik>
            <Modal name={MODALS.ADDRESS_ADD.name} willMount>
                <AddressAddModal />
            </Modal>
            <Modal name={MODALS.ADDRESS_CHANGE.name} willMount>
                <AddressEditModal />
            </Modal>
        </>
    );
});
