import {JivoMobileButtonClass} from 'modules/Jivo/constants';
import {useEffect} from 'react';

const {HighOffset} = JivoMobileButtonClass;

export const useSetJivoMobileButtonHighOffset = () => {
    useEffect(() => {
        document.body.classList.add(HighOffset);

        return () => {
            document.body.classList.remove(HighOffset);
        };
    }, []);
};
